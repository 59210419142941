"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConstantService = void 0;
var configuration_model_1 = require("./../models/configuration.model");
var ConstantService = /** @class */function () {
  function ConstantService() {
    this.externalConfig = configuration_model_1.Configuration.getInstance();
    this.server = this.externalConfig.server ? this.externalConfig.server : '';
    this.api = this.server + '/api/v1';
    this.routes = {
      auth: {
        authenticate: "".concat(this.api, "/auth/app/authenticate"),
        changePwdProtheus: "".concat(this.api, "/auth/app/changePwdProtheus"),
        authenticate_mingle: "".concat(this.api, "/auth/authenticate"),
        refresh: "".concat(this.api, "/auth/app/refresh"),
        refresh_params: '/config/params'
      },
      push: '/push',
      gateway: '/gateway',
      usage_metrics: {
        log: '/usage-metrics/log',
        logs: '/usage-metrics/logs'
      },
      crashr: '/crashr',
      feedback: '/feedback',
      events: '/events',
      gmaps: '/services/maps/gmaps'
    };
  }
  ConstantService.getInstance = function () {
    if (!ConstantService.instance) {
      ConstantService.instance = new ConstantService();
    }
    return ConstantService.instance;
  };
  ConstantService.USAGE_METRICS = {
    LOG_TYPES: {
      APP_INITIATED: 'APP_INITIATED',
      APP_PAUSED: 'APP_PAUSED',
      APP_RESUMED: 'APP_RESUMED',
      USER_LOGIN: 'USER_LOGIN',
      WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
      WRONG_ALIAS: 'WRONG_ALIAS',
      USER_LOGOUT: 'USER_LOGOUT',
      TOKEN_REFRESHED: 'TOKEN_REFRESHED',
      REFRESH_REFUSED: 'REFRESH_REFUSED',
      STATE_CHANGED: 'STATE_CHANGED',
      GATEWAY: 'GATEWAY',
      REDIRECT_PASSWORD: 'PROTHEUS_PASSWORD_REDIRECT',
      CHANGE_PASSWORD: 'CHANGE_PROTHEUS_PASSWORD'
    }
  };
  ConstantService.VERSION = '2.0.0';
  return ConstantService;
}();
exports.ConstantService = ConstantService;
