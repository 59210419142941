"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthService = void 0;
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var event_emitter_1 = require("../globals/event-emitter");
var http_1 = require("@totvs/http");
var constant_service_1 = require("./../constant/constant.service");
var logger_service_1 = require("./../logger/logger.service");
var metrics_service_1 = require("./../metrics/metrics.service");
var mingle_error_model_1 = require("./../models/mingle-error.model");
var session_service_1 = require("./../session/session.service");
var storage_service_1 = require("./../storage/storage.service");
var user_service_1 = require("./../user/user.service");
var AuthService = /** @class */function () {
  function AuthService() {
    var _this = this;
    this.eventEmitter = event_emitter_1.EventEmitterService.getInstance();
    this.constantService = new constant_service_1.ConstantService();
    this.http = new http_1.HttpPlugin();
    this.loggerService = new logger_service_1.LoggerService();
    this.metricService = new metrics_service_1.MetricService();
    this.sessionService = session_service_1.SessionService.getInstance();
    this.storageService = new storage_service_1.StorageService();
    this.userService = new user_service_1.UserService();
    this.header = {
      'Content-Type': 'application/json'
    };
    this.refreshToken = function () {
      var url = _this.generateUrl(_this.constantService.routes.auth.authenticate);
      var body = {
        _app: _this.sessionService.app_id,
        _client: _this.sessionService.client,
        _muser: _this.sessionService.user,
        rtoken: _this.sessionService.refresh_token,
        _set: _this.sessionService.set
      };
      return _this.http.post(url, body, {}, {
        headers: _this.header,
        observe: 'response'
      }).pipe((0, operators_1.map)(function (response) {
        _this.sessionService.token = response['access_token'];
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.TOKEN_REFRESHED, false);
        return response;
      }), (0, operators_1.catchError)(function (error) {
        _this.loggerService.errorMsg(error);
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.REFRESH_REFUSED, true);
        return (0, rxjs_1.throwError)(new mingle_error_model_1.MingleError(mingle_error_model_1.MingleError.CODE.REFRESH_TOKEN, mingle_error_model_1.MingleError.MESSAGE.REFRESH_TOKEN));
      }));
    };
  }
  AuthService.getInstance = function () {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  };
  AuthService.prototype.analytics = function (tokenSST, userAgent, alias) {
    var _this = this;
    var url = this.generateUrl(this.constantService.routes.auth.authenticate);
    var app_id = this.sessionService.app_id;
    var device_id = this.sessionService.device_id;
    if (!device_id) {
      this.storageService.getItem('device_id').then(function (res) {
        device_id = res.value;
      });
    }
    if (!app_id) {
      this.storageService.getItem('app_id').then(function (res) {
        app_id = res.value;
      });
    }
    var body = {
      gdc_authsst: tokenSST,
      user_agent: userAgent,
      alias: alias,
      device_id: device_id,
      app_id: app_id
    };
    return this.http.post(url, body, {
      headers: this.header,
      observe: 'response'
    }).pipe((0, operators_1.map)(function (response) {
      _this.userService.setUser(response['muser']);
      return _this.sessionService.createSession(response, 'analytics');
    }), (0, operators_1.catchError)(function (response) {
      if (response.status === 401) {
        if (response.error.message === 'Invalid Alias') {
          _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.WRONG_ALIAS, true);
        } else {
          _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.WRONG_CREDENTIALS, true);
        }
      }
      return (0, rxjs_1.throwError)(response);
    }), (0, operators_1.map)(function (auth) {
      _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.USER_LOGIN, true);
      return auth;
    }));
  };
  AuthService.prototype.getTokenWso2 = function (token) {
    var url = 'https://apimprod.totvs.com.br/api/token';
    var contentType = 'application/x-www-form-urlencoded';
    var bodyWso2 = 'grant_type=client_credentials';
    var httpOptions = {
      headers: {
        'Content-Type': contentType,
        'Authorization': 'Basic ' + token
      }
    };
    return this.http.post(url, bodyWso2, httpOptions).pipe((0, operators_1.map)(function (res) {
      return res;
    }));
  };
  AuthService.prototype.login = function (login, password, alias, preventEmmit) {
    var _this = this;
    var app_id = this.sessionService.app_id;
    var device_id = this.sessionService.device_id;
    var url = this.generateUrl(this.constantService.routes.auth.authenticate);
    if (!device_id) {
      this.storageService.getItem('device_id').then(function (res) {
        device_id = res.value;
      });
    }
    if (!app_id) {
      this.storageService.getItem('app_id').then(function (res) {
        app_id = res.value;
      });
    }
    var body = {
      login: login,
      password: password,
      alias: alias,
      device_id: device_id,
      app_id: app_id
    };
    this.setCredentials(password);
    return this.http.post(url, body).pipe((0, operators_1.map)(function (response) {
      var wso2Response = response['wso2'];
      _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.USER_LOGIN, false);
      if (!preventEmmit) {
        _this.userService.setUser(response['muser']);
      }
      _this.eventEmitter.emit['emit']('init-auth');
      if (wso2Response['wso2Tenant']) {
        var wso2Authorization = btoa("".concat(wso2Response['wso2ConsumerKey'], ":").concat(wso2Response['wso2ConsumerSecret']));
        _this.getTokenWso2(wso2Authorization).subscribe(function (res) {
          _this.sessionService.tokenWso2 = res.access_token;
          _this.sessionService.expiresInWso2 = res.expires_in;
          _this.sessionService.passwordWso2 = btoa("".concat(login, ":").concat(password));
        });
      }
      return _this.sessionService.createSession(response, response['auth_driver']);
    }), (0, operators_1.catchError)(function (error) {
      var containsErrorDataResponse = error['message'].toLocaleLowerCase() !== 'network error';
      var erroData = error['response']['data'];
      var errorDetails = {
        details: erroData,
        alias: body['alias'],
        user: body['login']
      };
      var dataAlias = {
        alias: body.alias
      };
      var dataUserAlias = {
        user: body.login,
        alias: body.alias
      };
      if (containsErrorDataResponse && erroData['message'] === 'Invalid Alias') {
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.WRONG_ALIAS, true, dataAlias);
      } else if (containsErrorDataResponse && error['response']['status'] === 302) {
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.REDIRECT_PASSWORD, true, errorDetails);
        _this.sessionService.createSessionChangePwd(erroData['errorInfo']);
      } else if (containsErrorDataResponse) {
        _this.metricService.register(erroData['message'], true, errorDetails);
      } else {
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.WRONG_CREDENTIALS, true, dataUserAlias);
      }
      return (0, rxjs_1.throwError)(error);
    }));
  };
  AuthService.prototype.changePwdProtheus = function (currentPassword, newPassword) {
    var _this = this;
    var url = this.generateUrl(this.constantService.routes.auth.changePwdProtheus);
    var sessionInfoChangePwd = this.sessionService.getSessionInfoChangePwd();
    var body = {
      login: sessionInfoChangePwd.login,
      currentPassword: currentPassword,
      newPassword: newPassword,
      set: sessionInfoChangePwd.set,
      host: sessionInfoChangePwd.host
    };
    return this.http.post(url, body).pipe((0, operators_1.map)(function (response) {
      var detailsData = {
        details: response['data'],
        user: sessionInfoChangePwd.login
      };
      _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.CHANGE_PASSWORD, true, detailsData);
      _this.sessionService.clearSessionChangePwd();
      return response;
    }), (0, operators_1.catchError)(function (error) {
      var containsErrorDataResponse = error['message'].toLocaleLowerCase() !== 'network error';
      var erroData = error['response']['data'];
      var errorDetails = {
        details: erroData,
        user: sessionInfoChangePwd.login
      };
      if (containsErrorDataResponse) {
        _this.metricService.register(erroData['message'], true, errorDetails);
      } else {
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.WRONG_CREDENTIALS, true, errorDetails);
      }
      return (0, rxjs_1.throwError)(error);
    }));
  };
  AuthService.prototype.logout = function () {
    return (0, rxjs_1.from)(this.logoutProcess());
  };
  AuthService.prototype.logoutProcess = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.metricService.registerAsync(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.USER_LOGOUT, true)];
          case 1:
            _a.sent();
            return [2 /*return*/, this.sessionService.clearSession().pipe((0, operators_1.map)(function () {
              _this.eventEmitter.emit['emit']('unregister-auth');
              _this.userService.setUser(undefined);
            }), (0, operators_1.catchError)(function (error) {
              _this.loggerService.errorMsg(error);
              return (0, rxjs_1.throwError)(new Error('Falha ao realizar o logout.'));
            }))];
        }
      });
    });
  };
  AuthService.prototype.protheus = function (login, password, alias, preventEmmit) {
    return this.login(login, password, alias, preventEmmit);
  };
  AuthService.prototype.refreshTokenAnalytics = function (tokenSST, user_agent) {
    var _this = this;
    var url = this.generateUrl(this.constantService.routes.auth.authenticate);
    var body = {
      _app: this.sessionService.app_id,
      _client: this.sessionService.client,
      _set: this.sessionService.set,
      _muser: this.sessionService.user,
      rtoken: this.sessionService.refresh_token,
      gdc_authsst: tokenSST,
      user_agent: user_agent
    };
    return this.http.post(url, body, {}, {
      headers: this.header,
      observe: 'response'
    }).pipe((0, operators_1.map)(function (data) {
      _this.sessionService.token = data['access_token'];
      _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.TOKEN_REFRESHED, false);
      return data;
    }), (0, operators_1.catchError)(function (err) {
      _this.loggerService.errorMsg(err);
      _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.REFRESH_REFUSED, true);
      return (0, rxjs_1.throwError)(new mingle_error_model_1.MingleError(mingle_error_model_1.MingleError.CODE.REFRESH_TOKEN, mingle_error_model_1.MingleError.MESSAGE.REFRESH_TOKEN));
    }));
  };
  AuthService.prototype.setCredentials = function (password) {
    if (password === void 0) {
      password = '';
    }
    this.sessionService.user_password = password;
  };
  AuthService.prototype.generateUrl = function (urlRoute) {
    var url = urlRoute;
    if (!url.includes('http')) {
      url = this.sessionService.configuration['server'] + urlRoute;
    }
    return url;
  };
  return AuthService;
}();
exports.AuthService = AuthService;
