"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpPlugin = void 0;
var http_rxios_1 = require("./http-rxios");
var HttpPlugin = /** @class */function () {
  function HttpPlugin() {
    this.http = new http_rxios_1.Rxios({
      headers: {}
    });
  }
  HttpPlugin.prototype.delete = function (url, queryParams, headers) {
    if (url === void 0) {
      url = '';
    }
    this.http = this.createHeaderRequest(headers);
    return this.http.delete(url, queryParams);
  };
  HttpPlugin.prototype.get = function (url, queryParams, headers) {
    if (url === void 0) {
      url = '';
    }
    this.http = this.createHeaderRequest(headers);
    return this.http.get(url, queryParams);
  };
  HttpPlugin.prototype.head = function (url, queryParams) {
    if (url === void 0) {
      url = '';
    }
    return this.http.head(url, queryParams);
  };
  HttpPlugin.prototype.patch = function (url, body, queryParams, headers) {
    if (url === void 0) {
      url = '';
    }
    this.http = this.createHeaderRequest(headers);
    return this.http.patch(url, body, queryParams);
  };
  HttpPlugin.prototype.post = function (url, body, queryParams, headers) {
    if (url === void 0) {
      url = '';
    }
    this.http = this.createHeaderRequest(headers);
    return this.http.post(url, body, queryParams);
  };
  HttpPlugin.prototype.put = function (url, body, queryParams, headers) {
    if (url === void 0) {
      url = '';
    }
    this.http = this.createHeaderRequest(headers);
    return this.http.put(url, body, queryParams);
  };
  HttpPlugin.prototype.request = function (method, url, body, queryParams, headers) {
    if (method === void 0) {
      method = '';
    }
    if (url === void 0) {
      url = '';
    }
    var request;
    this.http = this.createHeaderRequest(headers);
    method = method.toUpperCase();
    switch (method) {
      case 'GET':
        request = this.get(url, {
          params: queryParams
        }, headers);
        break;
      case 'HEAD':
        request = this.head(url, {
          params: queryParams
        });
        break;
      case 'POST':
        request = this.post(url, body, {
          params: queryParams
        }, headers);
        break;
      case 'PUT':
        request = this.put(url, body, {
          params: queryParams
        }, headers);
        break;
      case 'PATCH':
        request = this.patch(url, body, {
          params: queryParams
        }, headers);
        break;
      case 'DELETE':
        request = this.delete(url, {
          params: queryParams
        }, headers);
        break;
      default:
        throw new Error('Http method not supported');
    }
    return request;
  };
  HttpPlugin.prototype.createHeaderRequest = function (headers) {
    // tslint:disable-next-line
    return headers ? new http_rxios_1.Rxios({
      headers: headers
    }) : this.http;
  };
  return HttpPlugin;
}();
exports.HttpPlugin = HttpPlugin;
