"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Rxios = void 0;
var axios_1 = require("axios");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var Rxios = /** @class */function () {
  function Rxios(options) {
    if (options === void 0) {
      options = {};
    }
    this.options = options;
    this.http = axios_1.default.create(options);
  }
  Rxios.prototype.createRequest = function (method, url, queryParams, body) {
    var request;
    switch (method) {
      case 'GET':
        request = this.http.get(url, queryParams);
        break;
      case 'HEAD':
        request = this.http.head(url, queryParams);
        break;
      case 'POST':
        request = this.http.post(url, body, queryParams);
        break;
      case 'PUT':
        request = this.http.put(url, body, queryParams);
        break;
      case 'PATCH':
        request = this.http.patch(url, body, queryParams);
        break;
      case 'DELETE':
        request = this.http.delete(url, queryParams);
        break;
      default:
        throw new Error('Method not supported');
    }
    return rxjs_1.from(request).pipe(operators_1.map(function (response) {
      return response.data;
    }));
  };
  Rxios.prototype.get = function (url, queryParams) {
    return this.createRequest('GET', url, queryParams);
  };
  Rxios.prototype.head = function (url, queryParams) {
    return this.createRequest('HEAD', url, queryParams);
  };
  Rxios.prototype.post = function (url, body, queryParams) {
    return this.createRequest('POST', url, queryParams, body);
  };
  Rxios.prototype.put = function (url, body, queryParams) {
    return this.createRequest('PUT', url, queryParams, body);
  };
  Rxios.prototype.patch = function (url, body, queryParams) {
    return this.createRequest('PATCH', url, queryParams, body);
  };
  Rxios.prototype.delete = function (url, queryParams) {
    return this.createRequest('DELETE', url, queryParams);
  };
  return Rxios;
}();
exports.Rxios = Rxios;
