"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventEmitterService = void 0;
var events_1 = require("events");
var EventEmitterService = /** @class */function () {
  function EventEmitterService() {
    this.emit = new events_1.EventEmitter();
  }
  EventEmitterService.getInstance = function () {
    if (!EventEmitterService.instance) {
      EventEmitterService.instance = new EventEmitterService();
    }
    return EventEmitterService.instance;
  };
  return EventEmitterService;
}();
exports.EventEmitterService = EventEmitterService;
