"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MingleErrorCode = void 0;
var MingleErrorCode;
(function (MingleErrorCode) {
  MingleErrorCode[MingleErrorCode["INIT"] = 0] = "INIT";
  MingleErrorCode[MingleErrorCode["REFRESH_TOKEN"] = 1] = "REFRESH_TOKEN";
})(MingleErrorCode = exports.MingleErrorCode || (exports.MingleErrorCode = {}));
