import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPipe',
    standalone: false
})
export class DocumentsFormat implements PipeTransform {
  transform(cnpj: string): string {

    switch (cnpj?.length) {
      case 11:
        return cnpj.replace((/^(\d{3})(\d{3})(\d{3})(\d{2})/),
         '$1.$2.$3-$4');
      case 14:
        return cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
      default:
        return cnpj;
    }
  }

}
