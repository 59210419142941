<po-widget>
  <h2>{{ literals?.str0075 }}</h2>
  <p class="po-font-text-small po-mt-1">
    {{ literals?.str0076 }}
  </p>
  <div class="po-row">
    <po-upload
      class="po-mt-2"
      name="file"
      p-required
      p-url="https://po-sample-api.herokuapp.com/v1/uploads/addFile"
      p-multiple="true"
      [p-restrictions]="{ maxFileSize: '31457280' }"
      [(ngModel)]="file">
    </po-upload>
  </div>
</po-widget>
