import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MingleService } from '@totvs/mingle';
import { LoginService } from './login.service';
import { environmentProtheus } from 'src/environments/environment.protheus';
import { environment } from 'src/environments/environments';
import { Observable } from 'rxjs';
import { PgcProfile, PgcProfileItem } from '../models/pgc-profile.model';
import { Utils } from '../helper/utils';


let observable: any;

@Injectable({
  providedIn: 'root',
})
export class PreferencesProfileService {
  constructor(
    private http: HttpClient,
    private mingleService: MingleService,
    private loginService: LoginService
    ) {}

    requestOptions = {
      headers: new HttpHeaders({
      alias: 'SC1',
      fields: ['C1_QUANT'],
    }),

};

  createProfile(user, profileDefaultItems: Array<PgcProfileItem>) {

    //profile criado caso o user não possua ainda
    // valores devem ser false por padrão

    const profileBody: PgcProfile = {
      programName: "PGCA010",
      task: user,
      type: "preferences",
      value: JSON.stringify(profileDefaultItems)
    };

    if (!this.loginService.isProtheusLogged()) {

      observable = this.mingleService.gateway.post(`${environment.urlFrame}/profileService`, {body:profileBody});
      return observable;
    } else {
      return this.http.post(`${environmentProtheus.urlFrame}/profileService/`, profileBody);
    }
  }

  getProfilePreferences(user) {
    if (!this.loginService.isProtheusLogged()) {
      observable = this.mingleService.gateway.get(`${environment.urlFrame}/profileService/PGCA010/${user}/preferences`);
      return observable;
    } else {
      return this.http.get<any>(`${environmentProtheus.urlFrame}/profileService/PGCA010/${user}/preferences`);
    }
  }

  updateProfilePreferences(profileItem): Observable<any> {
    const user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : '{}';

    const profileBody: PgcProfile = {
      programName: "PGCA010",
      task: user,
      type: "preferences",
      value: JSON.stringify([profileItem])
    };

    if (!this.loginService.isProtheusLogged()) {
      return this.mingleService.gateway.put(`${environment.urlFrame}/profileService/PGCA010/${user}/preferences`, { body: profileBody }) as unknown as Observable<any>;
    } else {
      return this.http.put(`${environmentProtheus.urlFrame}/profileService/PGCA010/${user}/preferences`, profileBody);
    }
  }
}
