"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceInfo = void 0;
var DeviceInfo = /** @class */function () {
  function DeviceInfo() {}
  return DeviceInfo;
}();
exports.DeviceInfo = DeviceInfo;
