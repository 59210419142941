"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceService = void 0;
var guid_typescript_1 = require("guid-typescript");
var configuration_model_1 = require("../models/configuration.model");
var constant_service_1 = require("../constant/constant.service");
var device_info_model_1 = require("../models/device-info.model");
var DeviceService = /** @class */function () {
  function DeviceService(sessionObject) {
    this.sessionObject = sessionObject;
    this.config = configuration_model_1.Configuration.getInstance();
    this.sessionService = sessionObject;
  }
  DeviceService.prototype.getAllInfos = function () {
    var deviceInfo = new device_info_model_1.DeviceInfo();
    var isDevice = this.isDevice();
    var deviceInfos = this.setDeviceInfo(isDevice);
    deviceInfo.container_version = this.getContainerVersion(isDevice, deviceInfos);
    deviceInfo.isVirtual = this.sessionService.device_id ? this.sessionService.device_id : '';
    deviceInfo.language = this.sessionService.deviceLanguage ? this.sessionService.deviceLanguage : '';
    deviceInfo.manufacturer = this.getManufacturer(isDevice, deviceInfo);
    deviceInfo.model = this.getModel(isDevice, deviceInfo);
    deviceInfo.platform = this.config.modules.web || !this.config.modules.web && !isDevice ? this.getNavigatorPlatform() : deviceInfos['platform'];
    deviceInfo.platform_version = this.getPlatformVersion(isDevice, deviceInfos);
    deviceInfo.serial = this.getSerial(isDevice, deviceInfos);
    deviceInfo.uuid = this.getUuid(isDevice, deviceInfos);
    deviceInfo.versions = constant_service_1.ConstantService.VERSION;
    return deviceInfo;
  };
  DeviceService.prototype.getNavigatorPlatform = function () {
    var isDevice = this.isDevice();
    if (this.config.modules.web) {
      if (window.navigator && window.navigator.platform) {
        return 'webtesting: ' + window.navigator.platform;
      }
    } else if (!this.config.modules.web && !isDevice) {
      return 'node or platform without window';
    } else {
      return this.config.modules.deviceInstance['prototype'].getDeviceUUID();
    }
  };
  DeviceService.prototype.getContainerVersion = function (isDevice, deviceInfos) {
    return this.config.modules.web || !this.config.modules.web && !isDevice ? '' : deviceInfos['cordova'];
  };
  DeviceService.prototype.getManufacturer = function (isDevice, deviceInfos) {
    return this.config.modules.web || !this.config.modules.web && !isDevice ? '' : deviceInfos['manufacturer'];
  };
  DeviceService.prototype.getModel = function (isDevice, deviceInfos) {
    return this.config.modules.web || !this.config.modules.web && !isDevice ? '' : deviceInfos['model'];
  };
  DeviceService.prototype.getPlatformVersion = function (isDevice, deviceInfos) {
    return this.config.modules.web || !this.config.modules.web && !isDevice ? '' : deviceInfos['version'];
  };
  DeviceService.prototype.getSerial = function (isDevice, deviceInfos) {
    return this.config.modules.web || !this.config.modules.web && !isDevice ? '' : deviceInfos['serial'];
  };
  DeviceService.prototype.getUuid = function (isDevice, deviceInfos) {
    if (typeof window !== 'undefined') {
      return this.config.modules.web || !this.config.modules.web && !isDevice ? 'webtesting: ' + window.navigator.platform : deviceInfos['uuid'];
    } else {
      return guid_typescript_1.Guid.create().toString();
    }
  };
  DeviceService.prototype.isDevice = function () {
    return typeof this.config.modules.deviceInstance['prototype'] !== 'undefined';
  };
  DeviceService.prototype.setDeviceInfo = function (isDevice) {
    return !this.config.modules.web && isDevice ? this.config.modules.deviceInstance['prototype'].getDeviceInfos() : {};
  };
  return DeviceService;
}();
exports.DeviceService = DeviceService;
