"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MingleError = void 0;
var errors_code_enum_1 = require("./../enums/errors-code.enum");
var MingleError = /** @class */function (_super) {
  __extends(MingleError, _super);
  function MingleError(errorCode, message) {
    var _this = _super.call(this, message) || this;
    _this.code = errorCode;
    return _this;
  }
  MingleError.CODE = {
    INIT: errors_code_enum_1.MingleErrorCode.INIT,
    REFRESH_TOKEN: errors_code_enum_1.MingleErrorCode.REFRESH_TOKEN
  };
  MingleError.MESSAGE = {
    INIT: 'Erro ao iniciar a aplicação',
    REFRESH_TOKEN: 'Erro ao realizar o token refresh'
  };
  return MingleError;
}(Error);
exports.MingleError = MingleError;
