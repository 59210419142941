"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpService = void 0;
var HttpStatusCodes = require("http-status-codes");
var operators_1 = require("rxjs/operators");
var operators_2 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var guid_typescript_1 = require("guid-typescript");
var auth_service_1 = require("./../auth/auth.service");
var constant_service_1 = require("./../constant/constant.service");
var event_emitter_1 = require("../globals/event-emitter");
var logger_service_1 = require("../logger/logger.service");
var mingle_error_model_1 = require("./../models/mingle-error.model");
var session_service_1 = require("./../session/session.service");
var storage_service_1 = require("../storage/storage.service");
var user_service_1 = require("./../user/user.service");
var http_1 = require("@totvs/http");
var HttpService = /** @class */function () {
  function HttpService() {
    this.constantService = new constant_service_1.ConstantService();
    this.eventEmitter = event_emitter_1.EventEmitterService.getInstance();
    this.http = new http_1.HttpPlugin();
    this.isOnline = true;
    this.loggerService = new logger_service_1.LoggerService();
    this.sessionService = session_service_1.SessionService.getInstance();
    this.storage = storage_service_1.StorageService.getInstance();
    this.user = new user_service_1.UserService();
  }
  HttpService.prototype.eventEmitterNetworkOnline = function () {
    var _this = this;
    var options = {
      headers: {},
      body: {},
      params: {}
    };
    this.eventEmitter.emit.setMaxListeners(Infinity);
    this.eventEmitter['emit'].on('network-online', function () {
      _this.storage.getItem('failed_requests').then(function (res) {
        var _loop_1 = function (i) {
          options.headers = res['value'][i]['headers'];
          options.body = res['value'][i]['body'];
          options.params = res['value'][i]['params'];
          _this.request('POST', '/usage-metrics/log', options).subscribe(function (response) {
            _this.loggerService.infoMsg('Registered metric when network is online: ' + "".concat(JSON.stringify(response)));
            if (i === (res['value'].length - 1).toString()) {
              _this.storage.clearKey('failed_requests');
            }
          });
        };
        // tslint:disable-next-line:forin
        for (var i in res['value']) {
          _loop_1(i);
        }
      });
    });
  };
  // tslint:disable-next-line:cognitive-complexity
  HttpService.prototype.request = function (method, url, options, customHeaders, origin) {
    var _this = this;
    if (options === void 0) {
      options = {};
    }
    options.headers = this._setHeaders(options.headers, customHeaders, url, origin);
    var _url = this.setUrl(url);
    var isMetricsLogAPI = this.isMetricsLog(_url);
    this.storageRequestsOfflineNetwork(isMetricsLogAPI, options);
    return this.http.request(method, _url, options['body'], options['params'], options['headers']).pipe((0, operators_1.catchError)(function (error) {
      var _a, _b, _c;
      var originMingle = ((_c = (_b = (_a = error['response']) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.msg) === null || _c === void 0 ? void 0 : _c.origin) || null;
      var isNetworkError = _this.isNetworkErrorMessage(error['message']);
      if (!isNetworkError && error['response']['status'] === HttpStatusCodes.UNAUTHORIZED && originMingle === 'mingle') {
        var urlError_1 = _url;
        var body = _this.setBodyRequests();
        // tslint:disable-next-line:no-shadowed-variable
        var url_1 = _this.constantService.routes.auth.refresh;
        var header = {
          'Content-Type': 'application/json'
        };
        // - o auth_driver 'analytics' não refaz a request
        // - apenas passa pra frente o response da API de refresh
        if (_this.sessionService.authDriver === 'analytics') {
          return _this.refreshTokenAnalytics(body);
        }
        // - Refresh Token para WSO2
        // - O wso2 não possui uma rota especifica para refresh
        // - Por isso deve ser feito a partir do expires.
        if (_this.sessionService.wso2 && _this.sessionService.wso2['wso2Tenant']) {
          var current_time = new Date().getTime() / 1000;
          if (current_time > _this.sessionService.expiresInWso2) {
            _this.retryWso2RequestUnauthorized(options, customHeaders, method, _url);
          }
        }
        url_1 = url_1 === '/api/v1/auth/app/refresh' ? _this.sessionService.configuration['server'] + url_1 : url_1;
        return _this.http.post(url_1, body, {}, header).pipe((0, operators_1.catchError)(function () {
          return (0, rxjs_1.throwError)(new mingle_error_model_1.MingleError(mingle_error_model_1.MingleError.CODE.REFRESH_TOKEN, mingle_error_model_1.MingleError.MESSAGE.REFRESH_TOKEN));
        }), (0, operators_2.mergeMap)(function (response) {
          _this.sessionService.token = response['access_token'];
          delete options.headers['authorization'];
          var isMetricsLog = _this.isMetricsLog(url_1);
          if (_this.sessionService.tokenWso2 && !isMetricsLog) {
            options.headers = _this._setHeaders(options.headers, customHeaders, url_1);
          } else {
            Object.assign(options.headers, {
              authorization: "Bearer ".concat(_this.sessionService.token)
            });
          }
          return _this.http.request(method, urlError_1, options['body'], options['params'], options['headers']);
        }), (0, operators_1.map)(function (res) {
          if (method === 'GET' && options.persist) {
            _this.storage.setItem(url_1, JSON.stringify(res));
          }
          return res;
        }));
      } else {
        if (isNetworkError && isMetricsLogAPI) {
          var request = {
            id: guid_typescript_1.Guid.create(),
            body: options['body'] || {},
            params: options['params'] || {},
            headers: options['headers'] || {},
            status: false
          };
          _this.setFailedRequestsInStorage(request);
          _this.checkNetworkConnection();
          _this.isOnline = false;
          return (0, rxjs_1.of)({});
        }
        return (0, rxjs_1.throwError)(error);
      }
    }));
  };
  HttpService.prototype.checkNetworkConnection = function () {
    var _this = this;
    var source = (0, rxjs_1.interval)(15000);
    this.timer = source.pipe((0, operators_2.mergeMap)(function (val) {
      return (0, rxjs_1.of)(val);
    }));
    var subscription = this.timer.subscribe({
      next: function () {
        _this.http.head(_this.sessionService.configuration['server'] + '/test').subscribe(function () {
          _this.eventEmitter.emit['emit']('network-online');
          _this.isOnline = true;
          subscription.unsubscribe();
        }, function (err) {
          !_this.isNetworkErrorMessage(err['message']) ? subscription.unsubscribe() : _this.loggerService.infoMsg('offline network.');
        });
      }
    });
  };
  HttpService.prototype.isMetricsLog = function (url) {
    if (url === void 0) {
      url = '';
    }
    return url.includes('usage-metrics/log');
  };
  HttpService.prototype.isNetworkErrorMessage = function (message) {
    if (message === void 0) {
      message = '';
    }
    return message.toLocaleLowerCase() === 'network error';
  };
  HttpService.prototype.refreshTokenAnalytics = function (body) {
    var _this = this;
    var header = {
      'Content-Type': 'application/json'
    };
    var url = this.constantService.routes.auth.refresh;
    body = Object.assign(body, {
      gdc_authtt: this.sessionService.analytics.authTT,
      user_agent: this.sessionService.analytics.userAgent
    });
    return this.http.post(url, body, {}, header).pipe((0, operators_1.map)(function (response) {
      _this.sessionService.token = response['access_token'];
      return response;
    }), (0, operators_1.catchError)(function () {
      _this.user.setUser(undefined);
      _this.sessionService.clearSession().subscribe();
      return (0, rxjs_1.throwError)(new mingle_error_model_1.MingleError(mingle_error_model_1.MingleError.CODE.REFRESH_TOKEN, mingle_error_model_1.MingleError.MESSAGE.REFRESH_TOKEN));
    }));
  };
  HttpService.prototype.retryWso2RequestUnauthorized = function (options, customHeaders, method, url) {
    var _this = this;
    if (this.sessionService.wso2 && this.sessionService.wso2['wso2Tenant']) {
      var authService = new auth_service_1.AuthService();
      var user_login_1 = this.sessionService.user_login;
      var user_password_1 = this.sessionService.user_password;
      var wso2Authorization = btoa("".concat(this.sessionService.wso2['wso2ConsumerKey'], ":\n        ").concat(this.sessionService.wso2['wso2ConsumerSecret']));
      authService.getTokenWso2(wso2Authorization).subscribe(function (res) {
        _this.sessionService.tokenWso2 = res.access_token;
        _this.sessionService.expiresInWso2 = res.expires_in;
        _this.sessionService.passwordWso2 = btoa("".concat(user_login_1, ":").concat(user_password_1));
        options['headers'] = _this._setHeaders(options.headers, customHeaders, url);
        return _this.http.request(method, url, options['body'], options['params'], options['headers']);
      });
    }
  };
  HttpService.prototype.setBodyRequests = function () {
    return {
      _app: this.sessionService.app_id,
      _client: this.sessionService.client,
      _set: this.sessionService.set,
      _muser: this.sessionService.user,
      rtoken: this.sessionService.refresh_token
    };
  };
  HttpService.prototype.setFailedRequestsInStorage = function (request) {
    var _this = this;
    this.storage.getItem('failed_requests').then(function () {
      _this.storage.setAppendItemtoArray('failed_requests', request);
    }).catch(function () {
      _this.storage.setItem('failed_requests', [request]);
    });
  };
  HttpService.prototype.setUrl = function (url) {
    if (url === void 0) {
      url = '';
    }
    var isMetricsLog = this.isMetricsLog(url);
    var isValidURLRequest = this.constantService.api.includes('http');
    var api = !isValidURLRequest ? this.sessionService.configuration['server'] + this.constantService.api : this.constantService.api;
    return this.sessionService.wso2 && this.sessionService.wso2['wso2Tenant'] && !isMetricsLog ? url : "".concat(api).concat(url);
  };
  HttpService.prototype.storageRequestsOfflineNetwork = function (isMetricsLogAPI, options) {
    if (!this.isOnline && isMetricsLogAPI) {
      var request = {
        id: guid_typescript_1.Guid.create(),
        body: options['body'] || {},
        params: options['params'] || {},
        headers: options['headers'] || {},
        status: false
      };
      this.setFailedRequestsInStorage(request);
      return (0, rxjs_1.of)({});
    }
  };
  HttpService.prototype._setHeaders = function (headers, customHeaders, url, origin) {
    if (headers === void 0) {
      headers = {};
    }
    if (customHeaders === void 0) {
      customHeaders = [];
    }
    var isMetricsLog = this.isMetricsLog(url);
    var newHeaders = !headers ? {} : headers;
    var isValidHeadersDefault = this.sessionService.defaultHeaders && this.sessionService.defaultHeaders.length > 0;
    customHeaders = customHeaders.length === 0 && isValidHeadersDefault && !isMetricsLog ? this.sessionService.defaultHeaders : customHeaders;
    if (!newHeaders.hasOwnProperty('Content-Type')) {
      Object.assign(newHeaders, {
        'Content-Type': 'application/json'
      });
    }
    if (this.sessionService.wso2 && this.sessionService.wso2['wso2Tenant'] && !isMetricsLog) {
      delete newHeaders['authorization'];
      delete newHeaders['Authorization-Backend'];
      Object.assign(newHeaders, {
        authorization: 'Bearer ' + this.sessionService.tokenWso2
      });
      Object.assign(newHeaders, {
        'Authorization-Backend': "Basic ".concat(this.sessionService.passwordWso2)
      });
    } else {
      if (origin !== 'mingle') {
        delete newHeaders['authorization'];
        Object.assign(newHeaders, {
          authorization: "Bearer ".concat(this.sessionService.token)
        });
      }
    }
    customHeaders.map(function (header) {
      var _a;
      if (!isMetricsLog) {
        Object.assign(newHeaders, (_a = {}, _a["".concat(header.name)] = "".concat(header.value), _a));
      }
    });
    return newHeaders;
  };
  return HttpService;
}();
exports.HttpService = HttpService;
