"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GeolocationService = void 0;
var configuration_model_1 = require("../models/configuration.model");
var GeolocationService = /** @class */function () {
  function GeolocationService() {
    this.config = configuration_model_1.Configuration.getInstance();
  }
  GeolocationService.prototype.getCurrentLocation = function (options) {
    var geolocation;
    var isGeolocation = this.config.modules.geolocationInstance['prototype'] ? true : false;
    if (this.config.modules.web) {
      geolocation = new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
    } else if (!this.config.modules.web && !isGeolocation) {
      // tslint:disable-next-line:prefer-promise-shorthand
      geolocation = new Promise(function (resolve) {
        resolve('');
      });
    } else {
      geolocation = this.config.modules.geolocationInstance['prototype'].getCurrentPosition();
    }
    return geolocation;
  };
  GeolocationService.prototype.isLocationEnabled = function () {
    var isLocationEnabled;
    var isDiagnostic = this.config.modules.diagnosticInstance['prototype'] ? true : false;
    if (this.config.modules.web || !this.config.modules.web && !isDiagnostic) {
      isLocationEnabled = new Promise(function (resolve, reject) {
        if (typeof window !== 'undefined') {
          var isEnabled = window.navigator && window.navigator.geolocation !== undefined;
          resolve(isEnabled);
        } else {
          reject(false);
        }
      });
    } else {
      isLocationEnabled = this.config.modules.diagnosticInstance['prototype'].isLocationEnabled();
    }
    return isLocationEnabled;
  };
  return GeolocationService;
}();
exports.GeolocationService = GeolocationService;
