import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'tgc-form-message',
    templateUrl: './form-message.component.html',
    styleUrls: ['./form-message.component.css'],
    standalone: false
})
export class FormMessageComponent {

  @Input() text = '';

}
