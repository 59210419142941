"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onboardingKey = exports.serversUrl = void 0;
exports.serversUrl = {
  development: 'https://dev-mingle.totvs.com.br',
  testing: 'http://localhost:80',
  staging: 'https://hom-mingle.totvs.com.br',
  production: 'https://mingle.totvs.com.br',
  endpoint_api: '/api'
};
exports.onboardingKey = '780ed1a060ca530ea601977eb8dc8c7b:120edf109eeccc07a0b2828b08edb852=ee2d5e93daa3ad222d3a77f33acc8902f4dbf20c56a66e4b31ca09c68d2da080';
