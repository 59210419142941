"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TotvsStorage = void 0;
var storage_1 = require("./src/storage");
Object.defineProperty(exports, "TotvsStorage", {
  enumerable: true,
  get: function () {
    return storage_1.TotvsStorage;
  }
});
