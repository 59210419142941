"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OnboardingService = void 0;
var http_service_1 = require("./../http/http.service");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var constant_service_1 = require("./../constant/constant.service");
var env_variables_1 = require("./../constant/env-variables");
var OnboardingService = /** @class */function () {
  function OnboardingService() {
    this.httpPlugin = new http_service_1.HttpService();
    this.constantService = new constant_service_1.ConstantService();
  }
  OnboardingService.prototype.createAlias = function (onboardingData) {
    var createAliasEndpoint = '/onboarding/alias';
    var url = "".concat(this.constantService.server).concat(createAliasEndpoint);
    var body = onboardingData;
    var options = {
      body: body,
      headers: {
        'x-mingle-package-auth': env_variables_1.onboardingKey
      }
    };
    return this.httpPlugin.request('post', url, options).pipe((0, operators_1.map)(function (res) {
      return res;
    }), (0, operators_1.catchError)(function (error) {
      return (0, rxjs_1.throwError)(error);
    }));
  };
  return OnboardingService;
}();
exports.OnboardingService = OnboardingService;
