import { FormControl } from '@angular/forms';
import { LiteralsService } from 'src/app/shared/services/literals-service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { PoNotificationService, PoUploadFileRestrictions } from '@po-ui/ng-components';

import { LiteralsWords } from 'src/app/i18n/literals.model';

@Component({
    selector: 'tgc-attach-files',
    templateUrl: './attach-files.component.html',
    styleUrls: ['./attach-files.component.css'],
    standalone: false
})
export class AttachFilesComponent {

  // @ViewChild('form', { static: true }) form: FormControl;

  literals: LiteralsWords = this.literalsService.getLiterals();

  // allowedExtensions: string;
  file: string;
  // files: string;
  // uploaded: boolean = false;

  restrictions: PoUploadFileRestrictions;

  constructor(
    private literalsService: LiteralsService,
  ) {

    this.initializeLiterals();

  }

  initializeLiterals(): void {
    this.literals = this.literalsService.getLiterals();
  }

}
