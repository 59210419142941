"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Parameter = void 0;
var Parameter = /** @class */function () {
  function Parameter(key, value, _id) {
    if (key === void 0) {
      key = '';
    }
    if (value === void 0) {
      value = '';
    }
    if (_id === void 0) {
      _id = '';
    }
    this.key = key;
    this.value = value;
    this._id = _id;
  }
  return Parameter;
}();
exports.Parameter = Parameter;
