{
  "name": "@totvs/mingle",
  "version": " 0.0.1-20230927.2",
  "description": "Mingle core - client of Mingle",
  "author": "TOTVS SA",
  "scripts": {
    "build": "tsc -p ./tsconfig.json && cp package.json dist && cp package-lock.json dist && cp README.md dist && cd dist && npm ci && npm pack",
    "test": "jest --verbose ./src",
    "test-ci": "jest --verbose ./src --ci --reporters=default --reporters=jest-junit",
    "test-coverage": "jest --coverage",
    "test-watch": "jest --watchAll",
    "lint": "tslint -c tslint.json 'src/**/*.ts'"
  },
  "license": "ISC",
  "devDependencies": {
    "@totvs/thf-tslint": "^3.21.0",
    "@types/jest": "^27.4.0",
    "jest": "^27.5.1",
    "ts-jest": "^27.1.3",
    "typescript": "^4.9.4"
  },
  "dependencies": {
    "@totvs/http": "^0.0.1-20220310.2",
    "@totvs/storage": "latest",
    "@types/node": "^11.11.5",
    "events": "^3.0.0",
    "guid-typescript": "^1.0.9",
    "http-status-codes": "1.3.0",
    "jest-junit": "^13.0.0",
    "rxjs": "^7.8.0",
    "tslint": "^5.15.0"
  },
  "bundleDependencies": [
    "rxjs"
  ]
}
