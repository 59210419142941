<po-lookup #lookup
  [(ngModel)]="valueModel"
  [p-field-label]="fieldToFilter"
  [p-field-value]="fieldToFilter"
  [p-label]="label"
  [p-columns]="columns"
  [p-disabled]="disabled"
  [p-multiple]="false"
  [p-filter-service]="pgcLookupService"
  [p-infinite-scroll]="true"
  [p-no-autocomplete]="true"
  [p-clean]="true"
  (p-error)="error($event)"
  (p-selected)="onSelected($event, null)"
  (ngModelChange)="onChangeModel($event)"
  (keyup)="onKeyUp($event)">
</po-lookup>
