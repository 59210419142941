"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalizationService = void 0;
var configuration_model_1 = require("../models/configuration.model");
var GlobalizationService = /** @class */function () {
  function GlobalizationService() {
    this.config = configuration_model_1.Configuration.getInstance();
  }
  GlobalizationService.prototype.getBCP47Language = function () {
    var _this = this;
    return new Promise(function (resolve, reject) {
      if (typeof window !== 'undefined' && window.navigator && window.navigator.language) {
        resolve(window.navigator.language);
      } else if (!_this.config.modules.web && typeof window === 'undefined') {
        resolve('pt-BR');
      } else {
        var error = 'Error in get browser language';
        reject(error);
      }
    });
  };
  return GlobalizationService;
}();
exports.GlobalizationService = GlobalizationService;
