"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserDataService = void 0;
var http_service_1 = require("../http/http.service");
var UserDataService = /** @class */function () {
  function UserDataService() {
    this.httpPlugin = new http_service_1.HttpService();
  }
  UserDataService.prototype.getData = function (key) {
    return this.httpPlugin.request('GET', '/user-data/' + key);
  };
  // o any é porque esta danado erro pelo tipo do retorno
  UserDataService.prototype.postData = function (key, data) {
    return this.httpPlugin.request('PUT', '/user-data/' + key, {
      body: data
    });
  };
  return UserDataService;
}();
exports.UserDataService = UserDataService;
