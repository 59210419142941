"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MingleHttpInterceptor = void 0;
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var HttpStatusCodes = require("http-status-codes");
var auth_service_1 = require("../auth/auth.service");
var gateways_service_1 = require("../gateway/gateways.service");
var MingleHttpInterceptor = /** @class */function () {
  function MingleHttpInterceptor() {}
  MingleHttpInterceptor.prototype.intercept = function (req, next) {
    var _this = this;
    if (!req.url.includes('http')) {
      var newReq = this.updateHeaders(req);
      return next.handle(newReq).pipe((0, operators_1.catchError)(function (error) {
        if (error['status'] === HttpStatusCodes.UNAUTHORIZED) {
          return (0, rxjs_1.of)(_this.validRefreshToken(next, req));
        } else {
          throw error;
        }
      }));
    } else {
      return next.handle(req);
    }
  };
  MingleHttpInterceptor.prototype.updateHeaders = function (req) {
    var _gatewayService = new gateways_service_1.GatewayService();
    var newHeaders = _gatewayService.getHeaders();
    var url = _gatewayService.getUrl() + req.url;
    var cloneRequest = {
      url: url,
      setHeaders: {
        'Authorization': newHeaders.authorization,
        'Content-Type': newHeaders.contentType
      }
    };
    if (req.body instanceof FormData) {
      delete cloneRequest.setHeaders['Content-Type'];
    }
    return req.clone(cloneRequest);
  };
  MingleHttpInterceptor.prototype.validRefreshToken = function (next, req) {
    var _this = this;
    var authService = auth_service_1.AuthService.getInstance();
    authService.refreshToken().subscribe(function () {
      var newReq = _this.updateHeaders(req);
      return next.handle(newReq);
    });
  };
  return MingleHttpInterceptor;
}();
exports.MingleHttpInterceptor = MingleHttpInterceptor;
