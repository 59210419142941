"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserService = void 0;
var rxjs_1 = require("rxjs");
var UserService = /** @class */function () {
  function UserService() {
    this.userBehaviorSubject = new rxjs_1.BehaviorSubject(undefined);
  }
  UserService.prototype.getUser = function () {
    return this.userBehaviorSubject.asObservable();
  };
  UserService.prototype.setUser = function (userData) {
    this.user = userData;
    this.userBehaviorSubject.next(userData);
  };
  return UserService;
}();
exports.UserService = UserService;
