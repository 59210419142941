"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoggerService = void 0;
var LoggerService = /** @class */function () {
  function LoggerService() {}
  LoggerService.prototype.errorMsg = function (msg) {
    if (msg === void 0) {
      msg = '';
    }
    // tslint:disable-next-line:no-console
    console.error(msg);
  };
  LoggerService.prototype.infoMsg = function (msg) {
    if (msg === void 0) {
      msg = '';
    }
    // tslint:disable-next-line:no-console
    console.info(msg);
  };
  return LoggerService;
}();
exports.LoggerService = LoggerService;
