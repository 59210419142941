"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GatewayService = void 0;
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var constant_service_1 = require("./../constant/constant.service");
var http_service_1 = require("../http/http.service");
var metrics_service_1 = require("./../metrics/metrics.service");
var session_service_1 = require("./../session/session.service");
var GatewayService = /** @class */function () {
  function GatewayService() {
    this.constantService = new constant_service_1.ConstantService();
    this.httpPlugin = new http_service_1.HttpService();
    this.metricService = new metrics_service_1.MetricService();
    this.sessionService = session_service_1.SessionService.getInstance();
  }
  GatewayService.prototype.delete = function (url, options) {
    return this._request('DELETE', url, options);
  };
  GatewayService.prototype.get = function (url, options, queryParameters) {
    if (url && queryParameters) {
      url = this._addQueryParametersToUrl(url, queryParameters);
    }
    return this._request('GET', url, options, queryParameters);
  };
  GatewayService.prototype.getHeaders = function () {
    return {
      authorization: "Bearer ".concat(this.sessionService.token),
      contentType: 'application/json'
    };
  };
  GatewayService.prototype.getUrl = function () {
    return "".concat(this.constantService.api, "/gateway/").concat(this.sessionService.set, "/");
  };
  GatewayService.prototype.patch = function (url, options) {
    return this._request('PATCH', url, options);
  };
  GatewayService.prototype.post = function (url, options) {
    return this._request('POST', url, options);
  };
  GatewayService.prototype.put = function (url, options) {
    return this._request('PUT', url, options);
  };
  GatewayService.prototype.createOptionsObject = function (options) {
    if (!options) {
      return options = {
        headers: {},
        body: {}
      };
    }
    if (!options.headers) {
      options = Object.assign(options, {
        headers: {}
      });
    }
    if (!options.body) {
      options = Object.assign(options, {
        body: {}
      });
    }
    return options;
  };
  GatewayService.prototype._addQueryParametersToUrl = function (url, parameters) {
    var queryParameters = '';
    var keys = Object.keys(parameters);
    keys.forEach(function (key, i) {
      queryParameters += "".concat(key, "=").concat(parameters[key]);
      if (queryParameters !== '' && i < keys.length - 1) {
        queryParameters += '&';
      }
    });
    queryParameters = queryParameters === '' ? queryParameters : "?".concat(queryParameters);
    url = "".concat(url).concat(queryParameters);
    return url;
  };
  GatewayService.prototype._request = function (method, url, options, queryParameters) {
    var _this = this;
    options = this.setOptionsConfig(options);
    url = this.setURLConfig(url);
    return this.httpPlugin.request(method, url, options).pipe((0, operators_1.map)(function (res) {
      if (res['type'] === 4) {
        var data = _this._setMetricData(method, url, options, res, queryParameters);
        _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.GATEWAY, false, data);
      }
      return res;
    }), (0, operators_1.catchError)(function (error) {
      var data = _this._setMetricData(method, url, options, error, queryParameters);
      _this.metricService.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.GATEWAY, false, data);
      return (0, rxjs_1.throwError)(error);
    }));
  };
  GatewayService.prototype._setMetricData = function (method, url, options, res, queryParameters) {
    var data = {
      URL: url,
      method: method.toString(),
      response: res.status,
      request: {},
      statusCode: res.statusText
    };
    if (queryParameters) {
      data.request['queryParams'] = queryParameters;
    }
    return data;
  };
  GatewayService.prototype.setOptionsConfig = function (options) {
    options = this.createOptionsObject(options);
    if (this.sessionService.wso2) {
      options.body = 'grant_type=password&username=' + "".concat(this.sessionService.user_login) +
      // tslint:disable-next-line:no-hardcoded-credentials
      "".concat(this.sessionService.wso2['wso2Tenant']) + '&password=' + "".concat(this.sessionService.user_password);
    }
    return options;
  };
  GatewayService.prototype.setURLConfig = function (url) {
    var _url = '';
    _url = "/gateway/".concat(this.sessionService.set, "/").concat(url);
    if (this.sessionService.wso2 && this.sessionService.wso2['wso2Tenant']) {
      _url = this.sessionService.wso2['wso2Url'] + "/".concat(url);
    }
    return _url;
  };
  return GatewayService;
}();
exports.GatewayService = GatewayService;
