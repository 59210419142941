"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MetricService = void 0;
var rxjs_1 = require("rxjs");
var constant_service_1 = require("./../constant/constant.service");
var device_service_1 = require("./../device/device.service");
var geolocation_service_1 = require("./../geolocation/geolocation.service");
var http_service_1 = require("./../http/http.service");
var session_service_1 = require("./../session/session.service");
var storage_service_1 = require("./../storage/storage.service");
var MetricService = /** @class */function () {
  function MetricService() {
    this.constantService = new constant_service_1.ConstantService();
    this.sessionService = session_service_1.SessionService.getInstance();
    this.deviceService = new device_service_1.DeviceService(this.sessionService);
    this.geolocationService = new geolocation_service_1.GeolocationService();
    this.httpPlugin = new http_service_1.HttpService();
    this.storageService = new storage_service_1.StorageService();
  }
  MetricService.prototype.register = function (typeLog, anonymousMetric, data) {
    var _this = this;
    var position;
    this.geolocationService.isLocationEnabled().then(function (isAvailable) {
      if (isAvailable) {
        (0, rxjs_1.from)(_this.geolocationService.getCurrentLocation()).subscribe(function (geoposition) {
          if (geoposition !== '' && geoposition.coords) {
            position = "".concat(geoposition.coords.latitude, ",").concat(geoposition.coords.longitude);
          } else {
            position = "error: could not get location coordinates";
          }
          _this._send(typeLog, position, anonymousMetric, data).subscribe();
        }, function (error) {
          position = "error: User denied permission";
          _this._send(typeLog, position, anonymousMetric, data).subscribe();
        });
      } else {
        position = "error: gps disabled";
        _this._send(typeLog, position, anonymousMetric, data).subscribe();
      }
    }).catch(function (error) {
      position = "error: geolocation check not installed";
      _this._send(typeLog, position, anonymousMetric, data).subscribe();
    });
  };
  MetricService.prototype.registerAsync = function (typeLog, anonymousMetric, data) {
    if (data === void 0) {
      data = [];
    }
    return __awaiter(this, void 0, void 0, function () {
      var position;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.geolocation().then(function (res) {
              position = res;
            })];
          case 1:
            _a.sent();
            if (position) {
              this._send(typeLog, position, data).subscribe();
            }
            return [2 /*return*/];
        }
      });
    });
  };
  MetricService.prototype.geolocation = function () {
    var _this = this;
    return this.geolocationService.isLocationEnabled().then(function (isAvailable) {
      return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              if (!isAvailable) return [3 /*break*/, 2];
              _a = rxjs_1.from;
              return [4 /*yield*/, this.geolocationService.getCurrentLocation()];
            case 1:
              _a.apply(void 0, [_b.sent()]).subscribe(function (geoposition) {
                return Promise.resolve("".concat(geoposition['coords'].latitude, ",").concat(geoposition['coords'].longitude));
              }, function (error) {
                return Promise.resolve("error: User denied permission");
              });
              return [3 /*break*/, 3];
            case 2:
              return [2 /*return*/, Promise.resolve("error: gps disabled")];
            case 3:
              return [2 /*return*/];
          }
        });
      });
    }).catch(function (error) {
      return Promise.resolve("error: geolocation check not installed");
    });
  };
  MetricService.prototype.sendInitialMetrics = function () {
    var params = {
      device_info: this.deviceService.getAllInfos()
    };
    this.register(constant_service_1.ConstantService.USAGE_METRICS.LOG_TYPES.APP_INITIATED, true, params);
  };
  MetricService.prototype._send = function (typeLog, position, anonymousMetric, data) {
    var execId = this.sessionService.execId ? this.sessionService.execId : new Date().getTime();
    var mingleVersion = this.sessionService.mingleVersion;
    if (!mingleVersion) {
      this.storageService.getItem('mingle_version').then(function (res) {
        mingleVersion = res.value;
      });
    }
    var deviceInfo = this.sessionService.device_id ? this.sessionService.device_id : 'node or platform without window';
    var body = {
      _app: this.sessionService.app_id,
      _client: this.sessionService.client,
      _set: this.sessionService.set,
      device: deviceInfo,
      event_params: data,
      execId: execId,
      localstamp: position,
      timestamp: new Date().toString(),
      type: typeLog,
      user: this.sessionService.user,
      anonymousMetric: anonymousMetric
    };
    var options = {
      body: body,
      headers: {
        'X-MINGLE-VERSION': mingleVersion
      }
    };
    var url = this.constantService.routes.usage_metrics.log;
    return this.httpPlugin.request('POST', url, options);
  };
  return MetricService;
}();
exports.MetricService = MetricService;
