"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TotvsStorage = void 0;
// tslint:disable-next-line:no-var-requires
var isNode = require('detect-node');
var IdleQueue;
var PouchDB;
if (isNode) {
  // tslint:disable-next-line:no-var-requires
  PouchDB = require('pouchdb');
  // tslint:disable-next-line:no-var-requires
  IdleQueue = require('custom-idle-queue');
} else {
  window.global = window;
  // tslint:disable-next-line:no-var-requires
  PouchDB = require('pouchdb').default;
  // tslint:disable-next-line:no-var-requires
  IdleQueue = require('custom-idle-queue').default;
}
var TotvsStorage = /** @class */function () {
  function TotvsStorage(config) {
    this.setStoragePromise(config);
  }
  TotvsStorage.prototype.appendArrayToArray = function (key, value) {
    return __awaiter(this, void 0, void 0, function () {
      var data, newData;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.getArrayOfStorage(key)];
          case 1:
            data = _a.sent();
            newData = __spreadArray(__spreadArray([], data, true), value, true);
            return [2 /*return*/, this.set(key, newData)];
        }
      });
    });
  };
  TotvsStorage.prototype.appendItemToArray = function (key, value) {
    return __awaiter(this, void 0, void 0, function () {
      var data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.getArrayOfStorage(key)];
          case 1:
            data = _a.sent();
            data.push(value);
            return [2 /*return*/, this.set(key, data)];
        }
      });
    });
  };
  TotvsStorage.prototype.clear = function () {
    return this.db.destroy().then(function (res) {
      return res;
    }).catch(function (e) {
      return undefined;
    });
  };
  TotvsStorage.prototype.exists = function (key) {
    return this.get(key).then(function (data) {
      return Promise.resolve(data !== null);
    }).catch(function (e) {
      return false;
    });
  };
  TotvsStorage.prototype.get = function (key, lock) {
    if (lock === void 0) {
      lock = false;
    }
    return __awaiter(this, void 0, void 0, function () {
      var err_1;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!lock) return [3 /*break*/, 3];
            return [4 /*yield*/, this.requestIdlePromise()];
          case 1:
            _a.sent();
            return [4 /*yield*/, this.idleQueue.wrapCall(function () {
              return _this.db.get(key);
            })];
          case 2:
            return [2 /*return*/, _a.sent()];
          case 3:
            _a.trys.push([3, 5,, 6]);
            return [4 /*yield*/, this.db.get(key)];
          case 4:
            return [2 /*return*/, _a.sent()];
          case 5:
            err_1 = _a.sent();
            return [2 /*return*/, new Error(err_1)];
          case 6:
            return [2 /*return*/];
        }
      });
    });
  };
  TotvsStorage.prototype.getAll = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, this.db.allDocs().then(function (doc) {
          return doc.rows;
        }).catch(function (e) {
          return new Error(e);
        })];
      });
    });
  };
  TotvsStorage.prototype.getFirstItem = function (key) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, this.get(key).then(function (data) {
          return data;
        }).catch(function (e) {
          return new Error(e);
        })];
      });
    });
  };
  TotvsStorage.prototype.getItemAndRemove = function (key) {
    var _this = this;
    return this.get(key).then(function (data) {
      if (data === null) {
        return null;
      }
      var item;
      if (Array.isArray(data)) {
        item = data.shift();
        return _this.set(key, data).then(function () {
          return Promise.resolve(item);
        });
      } else {
        if (data.value) {
          item = data.value.shift();
          return _this.set(key, data).then(function () {
            return Promise.resolve(item);
          });
        } else {
          return new Error('Key content is not an array');
        }
      }
    }).catch(function (e) {
      return new Error(e);
    });
  };
  TotvsStorage.prototype.getItemByField = function (key, fieldName, fieldValue) {
    return this.get(key).then(function (storageRecords) {
      if (Array.isArray(storageRecords)) {
        var storageRecordsFiltered = storageRecords.find(function (storageRecord) {
          return storageRecord[fieldName] === fieldValue;
        });
        storageRecordsFiltered = storageRecordsFiltered || null;
        return Promise.resolve(storageRecordsFiltered);
      } else {
        if (storageRecords.value) {
          var storageRecordsFiltered = storageRecords.value.find(function (storageRecord) {
            return storageRecord[fieldName] === fieldValue;
          });
          storageRecordsFiltered = storageRecordsFiltered || null;
          return Promise.resolve(storageRecordsFiltered);
        }
        return new Error('Key content is not an array');
      }
    }).catch(function (e) {
      return new Error(e);
    });
  };
  TotvsStorage.prototype.length = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        return [2 /*return*/, this.db.allDocs().then(function (res) {
          return res.total_rows;
        }).catch(function (e) {
          return new Error(e);
        })];
      });
    });
  };
  TotvsStorage.prototype.lock = function () {
    this.idleQueue.lock();
  };
  TotvsStorage.prototype.remove = function (key) {
    var _this = this;
    return this.db.get(key).then(function (doc) {
      return _this.db.remove(doc);
    }).catch(function (e) {
      return new Error(e);
    });
  };
  TotvsStorage.prototype.removeItemFromArray = function (key, field, value) {
    return __awaiter(this, void 0, void 0, function () {
      var data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.getArrayOfStorage(key)];
          case 1:
            data = _a.sent();
            data = data.filter(function (item) {
              return item[field] !== value;
            });
            return [2 /*return*/, this.set(key, data)];
        }
      });
    });
  };
  TotvsStorage.prototype.requestIdlePromise = function () {
    return this.idleQueue.requestIdlePromise();
  };
  TotvsStorage.prototype.set = function (key, value, lock) {
    if (lock === void 0) {
      lock = false;
    }
    return __awaiter(this, void 0, void 0, function () {
      var e_1;
      var _this = this;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!lock) return [3 /*break*/, 2];
            return [4 /*yield*/, this.requestIdlePromise()];
          case 1:
            _a.sent();
            return [2 /*return*/, this.idleQueue.wrapCall(function () {
              return _this.db.put({
                key: key,
                value: value
              });
            })];
          case 2:
            _a.trys.push([2, 4,, 5]);
            return [4 /*yield*/, this.db.get(key).then(function (doc) {
              return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      if (!doc) return [3 /*break*/, 2];
                      return [4 /*yield*/, this.db.put({
                        _id: key,
                        _rev: doc._rev,
                        value: value
                      })];
                    case 1:
                      return [2 /*return*/, _a.sent()];
                    case 2:
                      return [4 /*yield*/, this.db.put({
                        _id: key,
                        value: value
                      })];
                    case 3:
                      return [2 /*return*/, _a.sent()];
                  }
                });
              });
            }).catch(function (error) {
              return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                  switch (_a.label) {
                    case 0:
                      return [4 /*yield*/, this.db.put({
                        _id: key,
                        value: value
                      })];
                    case 1:
                      return [2 /*return*/, _a.sent()];
                  }
                });
              });
            })];
          case 3:
            _a.sent();
            return [3 /*break*/, 5];
          case 4:
            e_1 = _a.sent();
            return [2 /*return*/, new Error(e_1)];
          case 5:
            return [2 /*return*/];
        }
      });
    });
  };
  TotvsStorage.prototype.unlock = function () {
    this.idleQueue.unlock();
  };
  TotvsStorage.prototype.setStoragePromise = function (config) {
    this.db = new PouchDB(config);
    this.idleQueue = new IdleQueue();
  };
  TotvsStorage.prototype.getArrayOfStorage = function (key) {
    return __awaiter(this, void 0, void 0, function () {
      var data;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.get(key).then(function (a) {
              return a.value;
            })];
          case 1:
            data = _a.sent();
            return [2 /*return*/, data || []];
        }
      });
    });
  };
  return TotvsStorage;
}();
exports.TotvsStorage = TotvsStorage;
