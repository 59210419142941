"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SessionService = void 0;
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var device_service_1 = require("../device/device.service");
var globalization_service_1 = require("../globalization/globalization.service");
var parameter_model_1 = require("./../models/parameter.model");
var storage_service_1 = require("../storage/storage.service");
var SessionService = /** @class */function () {
  function SessionService() {
    this.deviceService = new device_service_1.DeviceService(this);
    this.globalization = new globalization_service_1.GlobalizationService();
    this.keys = ['client', 'client_name', 'set', 'set_alias', 'user', 'user_login', 'token', 'refresh_token', 'params', 'authDriver', 'app_id', 'device_id', 'defaultHeaders', 'host'];
    this.storageService = new storage_service_1.StorageService();
    this._defaultHeaders = [];
    this._deviceLanguage = 'null';
    this._params = [new parameter_model_1.Parameter()];
    this.analytics = {
      userAgent: '',
      authTT: ''
    };
  }
  Object.defineProperty(SessionService.prototype, "app_id", {
    // - Getters
    get: function () {
      return this._app_id;
    },
    // - Setters
    set: function (app_id) {
      this.storageService.setItem('app_id', app_id);
      this._app_id = app_id;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "authDriver", {
    get: function () {
      return this._authDriver;
    },
    set: function (driver) {
      this.storageService.setItem('authDriver', driver);
      this._authDriver = driver;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "execId", {
    get: function () {
      return this._execId;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "client", {
    get: function () {
      return this._client;
    },
    set: function (client) {
      this.storageService.setItem('client', client);
      this._client = client;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "client_name", {
    get: function () {
      return this._client_name;
    },
    set: function (client_name) {
      this.storageService.setItem('client_name', client_name);
      this._client_name = client_name;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "configuration", {
    get: function () {
      return this._configuration;
    },
    set: function (config) {
      this._configuration = config;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "defaultHeaders", {
    get: function () {
      return this._defaultHeaders;
    },
    set: function (defaultHeaders) {
      this.storageService.setItem('defaultHeaders', defaultHeaders);
      this._defaultHeaders = defaultHeaders;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "device_id", {
    get: function () {
      return this._device_id;
    },
    set: function (device_id) {
      this.storageService.setItem('device_id', device_id);
      this._device_id = device_id;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "deviceLanguage", {
    get: function () {
      return this._deviceLanguage;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "expiresInWso2", {
    get: function () {
      return this._expiresInWso2;
    },
    set: function (expires_in) {
      this._expiresInWso2 = expires_in;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "mingleVersion", {
    get: function () {
      return this._mingleVersion;
    },
    set: function (mingle_version) {
      this.storageService.setItem('mingle_version', mingle_version);
      this._mingleVersion = mingle_version;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "params", {
    get: function () {
      return this._params;
    },
    set: function (params) {
      this.storageService.setItem('params', params);
      this._params = params;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "passwordWso2", {
    get: function () {
      return this._passwordWso2;
    },
    set: function (password) {
      this._passwordWso2 = password;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "refresh_token", {
    get: function () {
      return this._refresh_token;
    },
    set: function (refresh_token) {
      this.storageService.setItem('refresh_token', refresh_token);
      this._refresh_token = refresh_token;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "set", {
    get: function () {
      return this._set;
    },
    set: function (set) {
      this.storageService.setItem('set', set);
      this._set = set;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "host", {
    get: function () {
      return this._host;
    },
    set: function (host) {
      this.storageService.setItem('host', host);
      this._host = host;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "set_alias", {
    get: function () {
      return this._set_alias;
    },
    set: function (set_alias) {
      this.storageService.setItem('set_alias', set_alias);
      this._set_alias = set_alias;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "token", {
    get: function () {
      return this._token;
    },
    set: function (token) {
      this.storageService.setItem('token', token);
      this._token = token;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "tokenMingle", {
    get: function () {
      return this._tokenMingle;
    },
    set: function (token) {
      this.storageService.setItem('tokenMingle', token);
      this._tokenMingle = token;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "tokenWso2", {
    get: function () {
      return this._tokenWso2;
    },
    set: function (token) {
      this.storageService.setItem('tokenWso2', token);
      this._tokenWso2 = token;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "user", {
    get: function () {
      return this._user;
    },
    set: function (user) {
      this.storageService.setItem('user', user);
      this._user = user;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "user_login", {
    get: function () {
      return this._user_login;
    },
    set: function (user_login) {
      this.storageService.setItem('user_login', user_login);
      this._user_login = user_login;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "user_password", {
    get: function () {
      return this._user_password;
    },
    set: function (user_password) {
      this._user_password = user_password;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(SessionService.prototype, "wso2", {
    get: function () {
      return this._wso2;
    },
    set: function (wso2) {
      this.storageService.setItem('wso2', wso2);
      this._wso2 = wso2;
    },
    enumerable: false,
    configurable: true
  });
  SessionService.getInstance = function () {
    if (!SessionService.instance) {
      SessionService.instance = new SessionService();
    }
    return SessionService.instance;
  };
  SessionService.prototype.clearSession = function () {
    this._client = undefined;
    this._client_name = undefined;
    this._set = undefined;
    this._set_alias = undefined;
    this._user = undefined;
    this._user_login = undefined;
    this._user_password = undefined;
    this._token = undefined;
    this._refresh_token = undefined;
    this._params = undefined;
    this.wso2 = undefined;
    this.tokenWso2 = undefined;
    this.expiresInWso2 = undefined;
    this.passwordWso2 = undefined;
    return this.storageService.clearDatabase();
  };
  SessionService.prototype.clearSessionChangePwd = function () {
    this._set = undefined;
    this._user_login = undefined;
    this._user_password = undefined;
    this._host = undefined;
    return this.storageService.clearDatabase();
  };
  SessionService.prototype.createSession = function (authData, authDriver) {
    this.client = authData.client._id;
    this.client_name = authData.client.name;
    this.set = authData.set._id;
    this.set_alias = authData.set.alias;
    this.user = authData.muser._id;
    this.user_login = authData.muser.login;
    this.token = authData.access_token;
    this.refresh_token = authData.refresh_token;
    this.params = authData.params_values;
    this.authDriver = authDriver;
    return authData;
  };
  SessionService.prototype.createSessionChangePwd = function (authDataChangePwd) {
    this.user_login = authDataChangePwd.login;
    this.set = authDataChangePwd.set._id;
    this.host = authDataChangePwd.host._id;
    return authDataChangePwd;
  };
  SessionService.prototype.getSessionInfoChangePwd = function () {
    return {
      login: this.user_login,
      set: this.set,
      host: this.host
    };
  };
  SessionService.prototype.getSessionInfo = function () {
    return {
      client: this.client,
      client_name: this.client_name,
      set: this.set,
      set_alias: this.set_alias,
      user: this.user,
      user_login: this.user_login,
      token: this.token,
      refresh_token: this.refresh_token,
      params: this.params,
      authDriver: this.authDriver
    };
  };
  SessionService.prototype.init = function (config) {
    this._setExecId();
    this.setMingleVersion();
    this.app_id = config.app_identifier;
    this.device_id = this.deviceService.getNavigatorPlatform();
    return (0, rxjs_1.forkJoin)([this._setPreferredLanguage(), (0, rxjs_1.from)(this._retrieveDataFromStorage())]);
  };
  SessionService.prototype.isAuthenticationVariablesSet = function () {
    var _this = this;
    return this.keys.every(function (key) {
      // Se retornar
      // Null
      // Undefined
      // ''(string vazia)
      // São todos considerados falses, logo não é possível realizar chamadas autenticadas no mingle
      return _this["_".concat(key)];
    });
  };
  SessionService.prototype.setMingleVersion = function () {
    // tslint:disable-next-line:no-var-requires
    var packageJson = require('./../../package.json');
    this.mingleVersion = packageJson['version'];
  };
  SessionService.prototype._retrieveDataFromStorage = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _loop_1, this_1, _a, _b, _c, _i, i;
      var _this = this;
      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _loop_1 = function (i) {
              return __generator(this, function (_e) {
                switch (_e.label) {
                  case 0:
                    return [4 /*yield*/, this_1.storageService.getItem(this_1.keys[i]).then(function (res) {
                      if (res) {
                        _this["_".concat(_this.keys[i])] = res.value;
                      }
                    })];
                  case 1:
                    _e.sent();
                    return [2 /*return*/];
                }
              });
            };
            this_1 = this;
            _a = this.keys;
            _b = [];
            for (_c in _a) _b.push(_c);
            _i = 0;
            _d.label = 1;
          case 1:
            if (!(_i < _b.length)) return [3 /*break*/, 4];
            _c = _b[_i];
            if (!(_c in _a)) return [3 /*break*/, 3];
            i = _c;
            return [5 /*yield**/, _loop_1(i)];
          case 2:
            _d.sent();
            _d.label = 3;
          case 3:
            _i++;
            return [3 /*break*/, 1];
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  SessionService.prototype._setPreferredLanguage = function () {
    var _this = this;
    return (0, rxjs_1.from)(this.globalization.getBCP47Language()).pipe((0, operators_1.map)(function (response) {
      _this._deviceLanguage = response;
    }), (0, operators_1.catchError)(function (error) {
      return (0, rxjs_1.of)({
        value: ''
      });
    }));
  };
  SessionService.prototype._setExecId = function () {
    this._execId = new Date().getTime();
  };
  return SessionService;
}();
exports.SessionService = SessionService;
