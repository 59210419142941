"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Configuration = void 0;
var Configuration = /** @class */function () {
  function Configuration() {
    this.modules = {
      crashr: false,
      ocr: false,
      push_notification: false,
      usage_metrics: true,
      user_data: false,
      web: false,
      geolocationInstance: {
        getCurrentPosition: function () {}
      },
      diagnosticInstance: {
        isLocationEnabled: function () {}
      },
      deviceInstance: {
        getDeviceUUID: function () {},
        getDeviceInfos: function () {}
      },
      pushNotificationInstance: {
        init: function () {},
        unRegister: function () {}
      },
      ocrInstance: {
        readBusinessCard: function () {}
      }
    };
  }
  Configuration.getInstance = function () {
    if (!Configuration.instance) {
      Configuration.instance = new Configuration();
    }
    return Configuration.instance;
  };
  return Configuration;
}();
exports.Configuration = Configuration;
