"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.servers = void 0;
var env_variables_1 = require("../constant/env-variables");
exports.servers = {
  development: env_variables_1.serversUrl.development,
  staging: env_variables_1.serversUrl.staging,
  production: env_variables_1.serversUrl.production
};
