"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpPlugin = void 0;
var http_plugin_1 = require("./http/http.plugin");
Object.defineProperty(exports, "HttpPlugin", {
  enumerable: true,
  get: function () {
    return http_plugin_1.HttpPlugin;
  }
});
