"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StorageService = void 0;
var rxjs_1 = require("rxjs");
var storage_1 = require("@totvs/storage");
var StorageService = /** @class */function () {
  function StorageService() {
    this.storageService = new storage_1.TotvsStorage('__@totvs/mingle');
  }
  StorageService.getInstance = function () {
    if (!StorageService.instance) {
      StorageService.instance = new StorageService();
    }
    return StorageService.instance;
  };
  StorageService.prototype.clearDatabase = function () {
    var keys = ['client', 'client_name', 'set', 'set_alias', 'user', 'user_login', 'token', 'refresh_token', 'params', 'authDriver', 'failed_requests', 'mingle_version', 'defaultHeaders', 'host'];
    var promises = [];
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
      var key = keys_1[_i];
      promises.push(this.storageService.remove(key));
    }
    return (0, rxjs_1.forkJoin)(promises);
  };
  StorageService.prototype.clearKey = function (key) {
    if (key === void 0) {
      key = '';
    }
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.storageService.remove(key)];
          case 1:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  StorageService.prototype.getItem = function (key) {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.storageService.get(key)];
          case 1:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  StorageService.prototype.getAll = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4 /*yield*/, this.storageService.getAll()];
          case 1:
            return [2 /*return*/, _a.sent()];
        }
      });
    });
  };
  StorageService.prototype.getValues = function (keys) {
    var promises = [];
    for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
      var key = keys_2[_i];
      this.storageService.get(key).then(function (resolve) {
        promises.push(resolve);
      });
    }
    if (promises.length === 0) {
      promises = this.setPromiseError();
    }
    return (0, rxjs_1.forkJoin)(promises);
  };
  StorageService.prototype.setAppendItemtoArray = function (key, value) {
    return (0, rxjs_1.from)(this.storageService.appendItemToArray(key, value));
  };
  StorageService.prototype.setItem = function (key, value) {
    return (0, rxjs_1.from)(this.storageService.set(key, value));
  };
  StorageService.prototype.setPromiseError = function () {
    return [new Promise(function (res) {
      return res({
        error: true
      });
    })];
  };
  return StorageService;
}();
exports.StorageService = StorageService;
