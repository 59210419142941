"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GmapsService = void 0;
var constant_service_1 = require("../constant/constant.service");
var session_service_1 = require("../session/session.service");
var http_service_1 = require("./../http/http.service");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var GmapsService = /** @class */function () {
  function GmapsService() {
    this.httpPlugin = new http_service_1.HttpService();
    this.sessionService = session_service_1.SessionService.getInstance();
    this.constantService = new constant_service_1.ConstantService();
    this.gMapsApiUrl = "".concat(this.constantService.server).concat(this.constantService.routes.gmaps);
  }
  GmapsService.prototype.gateway = function (endpoint) {
    endpoint = endpoint ? endpoint : '';
    var urlWithAppId = "".concat(this.gMapsApiUrl, "?appId=").concat(this.sessionService.app_id);
    var fullEndpoint = "".concat(urlWithAppId, "&mapsEndpoint=").concat(endpoint);
    return this.requestGMaps(fullEndpoint);
  };
  GmapsService.prototype.requestGMaps = function (url) {
    return this.httpPlugin.request('get', url).pipe((0, operators_1.map)(function (res) {
      return res;
    }), (0, operators_1.catchError)(function (error) {
      return (0, rxjs_1.throwError)(error);
    }));
  };
  return GmapsService;
}();
exports.GmapsService = GmapsService;
