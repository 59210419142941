"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MingleService = void 0;
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var event_emitter_1 = require("../globals/event-emitter");
var auth_service_1 = require("../auth/auth.service");
var configuration_model_1 = require("../models/configuration.model");
var constant_service_1 = require("../constant/constant.service");
var gateways_service_1 = require("../gateway/gateways.service");
var onboarding_service_1 = require("../onboarding/onboarding.service");
var http_service_1 = require("../http/http.service");
var logger_service_1 = require("../logger/logger.service");
var metrics_service_1 = require("../metrics/metrics.service");
var mingle_error_model_1 = require("../models/mingle-error.model");
var session_service_1 = require("../session/session.service");
var storage_service_1 = require("../storage/storage.service");
var user_service_1 = require("../user/user.service");
var user_data_service_1 = require("../user/user-data.service");
var gmaps_service_1 = require("../gmaps/gmaps.service");
var servers_enum_1 = require("../enums/servers.enum");
var MingleService = /** @class */function () {
  function MingleService() {
    this.auth = new auth_service_1.AuthService();
    this.configInstance = configuration_model_1.Configuration.getInstance();
    this.constantService = new constant_service_1.ConstantService();
    this.eventEmitter = event_emitter_1.EventEmitterService.getInstance();
    this.gateway = new gateways_service_1.GatewayService();
    this.loggerService = new logger_service_1.LoggerService();
    this.httpService = new http_service_1.HttpService();
    this.metricService = new metrics_service_1.MetricService();
    this.sessionService = session_service_1.SessionService.getInstance();
    this.storageService = storage_service_1.StorageService.getInstance();
    this.userData = new user_data_service_1.UserDataService();
    this.userService = new user_service_1.UserService();
    this.gmaps = new gmaps_service_1.GmapsService();
    this.onboarding = new onboarding_service_1.OnboardingService();
    this.servers = servers_enum_1.servers;
  }
  MingleService.getInstance = function () {
    if (!MingleService.instance) {
      MingleService.instance = new MingleService();
    }
    return MingleService.instance;
  };
  MingleService.prototype.configMingleURL = function (url) {
    if (url === void 0) {
      url = '';
    }
    return this.sessionService.configuration['server'] + this.constantService.api + "/gateway/".concat(this.sessionService.set, "/").concat(url);
  };
  MingleService.prototype.getAccessToken = function () {
    return this.sessionService.token;
  };
  MingleService.prototype.getBodyToRefreshTokenAPI = function () {
    return {
      _app: this.sessionService.app_id,
      _client: this.sessionService.client,
      _set: this.sessionService.set,
      _muser: this.sessionService.user,
      rtoken: this.sessionService.refresh_token
    };
  };
  MingleService.prototype.getParams = function () {
    return this.sessionService.params;
  };
  MingleService.prototype.getAllStorage = function () {
    return this.storageService.getAll();
  };
  MingleService.prototype.getSessionInfo = function () {
    return this.sessionService.getSessionInfo();
  };
  MingleService.prototype.getUser = function () {
    return this.userService.getUser();
  };
  MingleService.prototype.getUserData = function (key) {
    return this.userData.getData(key);
  };
  MingleService.prototype.getRefreshTokenURL = function () {
    return this.sessionService.configuration['server'] + '/api/v1/auth/app/refresh';
  };
  MingleService.prototype.init = function (server, appId, web) {
    var _this = this;
    if (server && appId && web) {
      var config = {
        app_identifier: appId,
        server: server,
        modules: {
          web: web
        }
      };
      this.setConfiguration(config);
    }
    return this.sessionService.init(this.configInstance).pipe((0, operators_1.map)(function (res) {
      _this.metricService.sendInitialMetrics();
      _this.eventEmitter.emit['emit']('init');
      _this.httpService.eventEmitterNetworkOnline();
      _this.httpService.checkNetworkConnection();
      return res;
    }), (0, operators_1.catchError)(function (err) {
      _this.metricService.sendInitialMetrics();
      return (0, rxjs_1.throwError)(new mingle_error_model_1.MingleError(mingle_error_model_1.MingleError.CODE.INIT, mingle_error_model_1.MingleError.MESSAGE.INIT));
    })).toPromise();
  };
  MingleService.prototype.isReadyForAuthenticatedRequests = function () {
    if (!this.sessionService.isAuthenticationVariablesSet()) {
      throw new Error('There is no data to perform authenticated requests');
    }
  };
  // Porque passar a ação de registrar na session o auth token e o usar agent?
  MingleService.prototype.registerAnalyticsToken = function (authTT, userAgent) {
    this.sessionService.analytics.authTT = authTT;
    this.sessionService.analytics.userAgent = userAgent;
  };
  MingleService.prototype.registerMetric = function (typeLog, data) {
    this.metricService.register(typeLog, true, data);
  };
  MingleService.prototype.request = function (method, url, options, setMingleURL) {
    if (method === void 0) {
      method = '';
    }
    if (url === void 0) {
      url = '';
    }
    if (setMingleURL) {
      url = this.configMingleURL(url);
    }
    return this.httpService.request(method, url, options);
  };
  MingleService.prototype.saveUserData = function (key, data) {
    return this.userData.postData(key, data);
  };
  MingleService.prototype.setConfiguration = function (config) {
    if (config === void 0) {
      config = {};
    }
    this.configInstance.app_identifier = config['app_identifier'];
    this.configInstance.server = config['server'];
    this.configInstance.modules.crashr = config['modules']['crashr'];
    this.configInstance.modules.usage_metrics = config['modules']['usage_metrics'];
    this.configInstance.modules.web = config['modules']['web'];
    this.sessionService.configuration = this.configInstance;
  };
  MingleService.prototype.setDefaultHeaders = function (defaultHeaders) {
    this.sessionService.defaultHeaders = defaultHeaders;
  };
  MingleService.prototype.setTokenInSession = function (token) {
    if (token === void 0) {
      token = '';
    }
    this.sessionService.token = token;
  };
  MingleService.prototype.use = function (object) {
    switch (object.name) {
      case 'GeolocationPlugin':
        this.configInstance.modules.geolocationInstance = object;
        this.configInstance.modules.diagnosticInstance = object;
        break;
      case 'DevicePlugin':
        this.configInstance.modules.deviceInstance = object;
        break;
    }
  };
  return MingleService;
}();
exports.MingleService = MingleService;
